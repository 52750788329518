import { message } from "antd";
import axios from "axios";
import {jwtDecode} from "jwt-decode";
import { createContext, useContext, useState } from "react";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const localAuthToken = localStorage.getItem("token");
  const localCurrentUser = JSON.parse(localStorage.getItem("user"));

  const [token, setToken] = useState(localAuthToken);
  const [currUser, setCurrUser] = useState(localCurrentUser);

  const [loginLoading, setLoginLoading] = useState(false);

  const getCurrentUser = async (authToken) => {
    try {
      const currentUserRes = await axios.get(`/current-user/`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${authToken ?? localStorage?.getItem("token")}`,
        },
      });
      console.log(currentUserRes, "currentUserRes");
      return currentUserRes;
    } catch (error) {
      console.log("getCurrentUser error", error);
    }
  };

  const loginHandler = async (values) => {
    const formData = new FormData();
    formData.append("email", values?.email);
    formData.append("password", values?.password);
    setLoginLoading(true);
    try {
      const loginResponse = await axios.post(`/token/`, formData);
      message.success("Successfully logged in!");
      const currUserResponse = await getCurrentUser(
        loginResponse?.data?.access
      );
      setToken(loginResponse?.data?.access);
      setCurrUser(currUserResponse?.data);

      if (loginResponse?.data?.access) {
        localStorage.setItem("token", loginResponse?.data?.access);
      }
      if (currUserResponse?.data) {
        localStorage.setItem("user", JSON.stringify(currUserResponse?.data));
      }
    } catch (error) {
      message.error(
        error?.response?.data?.message ??
          error?.response?.data?.detail ??
          "Something went wrong!"
      );
    } finally {
      setLoginLoading(false);
      // window.location.reload();
    }
  };

  const logoutHandler = () => {
    setToken("");
    setCurrUser(null);
    localStorage.clear();
  };

  if (token) {
    const decodedToken = jwtDecode(token);
    if (decodedToken.exp * 1000 < Date.now()) {
      logoutHandler();
    }
  }

  return (
    <AuthContext.Provider
      value={{
        token,
        currUser,
        loginHandler,
        logoutHandler,
        loginLoading,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

const useAuth = () => useContext(AuthContext);

export { useAuth, AuthProvider };
